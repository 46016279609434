export const commonLocalization = {
  Vendors: {
    en: 'Vendors',
    ru: 'Вендоры',
  },
  Vendor: {
    en: 'Vendor',
    ru: 'Вендор',
  },
  Keys: {
    en: 'Keys',
    ru: 'Ключи',
  },
  Name: {
    en: 'Name',
    ru: 'Имя',
  },
  'E-mail': {
    en: 'Email',
    ru: 'Email',
  },
  'Add vendor': {
    en: 'Add vendor',
    ru: 'Добавить вендора',
  },
  Add: {
    en: 'Add',
    ru: 'Добавить',
  },
  Cancel: {
    en: 'Cancel',
    ru: 'Отменить',
  },
  'Name field is required': {
    en: 'Name field is required',
    ru: 'Поле Имя обязательно',
  },
  'E-mail field is required': {
    en: 'Email field is required',
    ru: 'Поле Email обязательно',
  },
  'Failed adding vendor': {
    en: 'Failed to add vendor',
    ru: 'Не удалось добавить вендора',
  },
  Key: {
    en: 'Key',
    ru: 'Ключ',
  },
  'Created At': {
    en: 'Created at',
    ru: 'Создан',
  },
  'Activated At': {
    en: 'Activated at',
    ru: 'Активирован',
  },
  Status: {
    en: 'Status',
    ru: 'Статус',
  },
  'Sessions Limits': {
    en: 'Session limits',
    ru: 'Лимит сессий',
  },
  'Time Limits In Days': {
    en: 'Day limits',
    ru: 'Лимит дней',
  },
  Description: {
    en: 'Description',
    ru: 'Описание',
  },
  'Additional information': {
    en: 'Additional information',
    ru: 'Дополнительная информация',
  },
  'Continue Operating After Exceeding Limits': {
    en: 'Continue operation after exceeding limits',
    ru: 'Работать после выхода за лимит',
  },
  REPLACED: {
    en: 'Replaced',
    ru: 'Заменен',
  },
  REVOKED: {
    en: 'Revoked',
    ru: 'Отозван',
  },
  CREATED: {
    en: 'Created',
    ru: 'Создан',
  },
  'ACTIVATION_PENDING ': {
    en: 'Waiting for activation',
    ru: 'Ожидание активации',
  },
  ACTIVATED: {
    en: 'Activated',
    ru: 'Активирован',
  },
  Yes: {
    en: 'Yes',
    ru: 'Да',
  },
  No: {
    en: 'No',
    ru: 'Нет',
  },
  'Old Key': {
    en: 'Old key',
    ru: 'Старый ключ',
  },
  'Create new key': {
    en: 'Create a key',
    ru: 'Создание лицензионного ключа',
  },
  'Do you really want to revoke the key?': {
    en: 'Are you sure you want to delete the key?',
    ru: 'Вы действительно хотите отозвать ключ?',
  },
  'Revoke key': {
    en: 'Revoke key',
    ru: 'Отозвать ключ',
  },
  'Keys history': {
    en: 'Key history',
    ru: 'История ключей',
  },
  'Used limits': {
    en: 'Used limits',
    ru: 'Использованные лимиты',
  },
  'Sessions used': {
    en: 'Sessions used',
    ru: 'Использовано сессий',
  },
  'Expired at': {
    en: 'Expires',
    ru: 'Истекает',
  },
  'History not found': {
    en: 'History not found',
    ru: 'История не найдена',
  },
  "Couldn't load keys": {
    en: 'Failed to load',
    ru: 'Не удалось загрузить',
  },
  "Couldn't load vendors": {
    en: 'Failed to load vendors',
    ru: 'Не удалось загрузить вендоров',
  },
  'Vendor field is required': {
    en: 'Vendor field is required',
    ru: 'Поле Вендор обязательно',
  },
  'Session limits field is required': {
    en: 'Session limits field is required',
    ru: 'Поле Лимит сессий обязательно',
  },
  'Time limit in days field is required': {
    en: 'Day limits field is required',
    ru: 'Поле Лимит дней обязательно',
  },
  'Rows per page': {
    en: 'On page',
    ru: 'На странице',
  },
  Of: {
    en: 'of',
    ru: 'из',
  },
  'The key wasnt created': {
    en: 'The key was not created',
    ru: 'Ключ не был создан',
  },
  Sort: {
    en: 'Sort',
    ru: 'Сортировать',
  },
  'Import key': {
    en: 'Import key',
    ru: 'Импортировать ключ',
  },
  'Key import error': {
    en: 'Key import error',
    ru: 'Ошибка импорта ключа',
  },
  'Key name is required': {
    en: 'Key name is required',
    ru: 'Имя ключа обязательно',
  },
  'Key description is required': {
    en: 'Key description is required',
    ru: 'Описание ключа обязательно',
  },
  'Key description': {
    en: 'Key description',
    ru: 'Описание ключа',
  },
  'Key name': {
    en: 'Key name',
    ru: 'Имя ключа',
  },
  Import: {
    en: 'Import',
    ru: 'Импортировать',
  },
  'Export key': {
    en: 'Export key',
    ru: 'Экспортировать ключ',
  },
  'Key was is copied to clipboard': {
    en: 'Key copied to clipboard',
    ru: 'Ключ скопирован в буфер обмена',
  },
  'Signed key was is copied to clipboard': {
    en: 'Signed key copied to clipboard',
    ru: 'Подписанный ключ скопирован в буфер обмена',
  },
  'Click to copy key': {
    en: 'Click to copy key',
    ru: 'Нажмите, чтобы скопировать ключ',
  },
  Actions: {
    en: 'Actions',
    ru: 'Действия',
  },
  'Report emails': {
    en: 'Email for notifications',
    ru: 'Email для рассылки уведомлений',
  },
  'Add email': {
    en: 'Add email',
    ru: 'Добавить email',
  },
  'Report email': {
    en: 'Email for notifications',
    ru: 'Email для уведомлений',
  },
  Statistics: {
    en: 'Statistics',
    ru: 'Статистика',
  },
  'Key Access History': {
    en: 'Key access history',
    ru: 'История обращений по ключу',
  },
  'Select custom date range': {
    en: 'Select a period',
    ru: 'Выбрать день или период',
  },
  week: {
    en: 'last week',
    ru: 'неделя',
  },
  'two weeks': {
    en: 'last two weeks',
    ru: 'две недели',
  },
  month: {
    en: 'month',
    ru: 'месяц',
  },
  day: {
    en: 'day',
    ru: 'день',
  },
  period: {
    en: 'Period',
    ru: 'Период',
  },
  choose: {
    en: 'select',
    ru: 'выбрать',
  },
  'Select another': {
    en: 'Select another',
    ru: 'Выбрать другой',
  },
  'selected period': {
    en: 'selected period:',
    ru: 'выбран период:',
  },
  'selected day': {
    en: 'selected day:',
    ru: 'выбран день:',
  },
  Continue: {
    en: 'Continue',
    ru: 'Продолжить',
  },
  'last day': {
    en: 'Last 24 hours',
    ru: 'Последние сутки',
  },
  'last hour': {
    en: 'Last hour',
    ru: 'Последний час',
  },
  'last 4 hours': {
    en: 'Last 4 hours',
    ru: 'Последние 4 часа',
  },
  'last 10 hours': {
    en: 'Last 10 hours',
    ru: 'Последние 10 часов',
  },
  'No vendors': {
    en: 'No vendors',
    ru: 'Нет вендоров',
  },
  'Tariff name': {
    en: 'Key name',
    ru: 'Название ключа',
  },
  Client: {
    en: 'Client',
    ru: 'Клиент',
  },
  'Key action': {
    en: 'Key validity',
    ru: 'Действие ключа',
  },
  'Key validity start': {
    en: 'Start',
    ru: 'Начало',
  },
  'After activation': {
    en: 'After activation',
    ru: 'После активации',
  },
  'By date': {
    en: 'By date',
    ru: 'По дате',
  },
  'Key validity period select': {
    en: '$[1] year',
    ru: '$[1] год',
  },
  'Activation date': {
    en: 'Activation date',
    ru: 'Дата активации',
  },
  'Tariff by': {
    en: 'Billing by ',
    ru: 'Тарифицировать по ',
  },
  'by sessions': {
    en: 'sessions',
    ru: 'сессиям',
  },
  'by uniques': {
    en: 'unique users',
    ru: 'уникам',
  },
  'Additional session packages': {
    en: 'Additional session packages',
    ru: 'Дополнительные пакеты сессий',
  },
  'Uniques count by year': {
    en: 'Unique users/year',
    ru: 'Кол-во уников/год',
  },
  'Uniques count by period': {
    en: 'Number of unique users/period',
    ru: 'Кол-во уников/период',
  },
  Max: {
    en: 'Max: $[1]',
    ru: 'Макс: $[1]',
  },
  'Additional package count': {
    en: 'Number of packages',
    ru: 'Кол-во пакетов',
  },
  'Package size': {
    en: 'Package size',
    ru: 'Размер пакета',
  },
  'No limit': {
    en: 'Not limited',
    ru: 'Не ограничено',
  },
  'Session duration': {
    en: 'Billing session duration',
    ru: 'Длительность тарификационной сессии',
  },
  hour: {
    en: 'Hour',
    ru: 'Час',
  },
  minute: {
    en: 'Minute',
    ru: 'Минута',
  },
  'For all channels': {
    en: 'For all channels',
    ru: 'Для всех каналов',
  },
  Zendesk: {
    en: 'Zendesk',
    ru: 'Zendesk',
  },
  Comment: {
    en: 'Comment',
    ru: 'Комментарий',
  },
  'Add key modal button': {
    en: 'Create license key',
    ru: 'Создать лицензионный ключ',
  },
  'Session count': {
    en: 'Number of sessions',
    ru: 'Кол-во сессий',
  },
  'For one month': {
    en: 'For one month',
    ru: 'На месяц',
  },
  'No limit period': {
    en: 'Indefinitely',
    ru: 'Бессрочно',
  },
  'For one year': {
    en: 'For a year',
    ru: 'На год',
  },
  'Message key action': {
    en: 'Key validity',
    ru: 'Действие ключа',
  },
  'Message tariffication': {
    en: 'Plan limits',
    ru: 'Тарификация',
  },
  'Message uniques count by year': {
    en: 'Unique users/year',
    ru: 'Кол-во уников/год',
  },
  'Message uniques count by period': {
    en: 'Number of unique users/period',
    ru: 'Кол-во уников/период',
  },
  'Message sessions count': {
    en: 'Number of sessions',
    ru: 'Кол-во сессий',
  },
  'Message additional users package': {
    en: 'Additional unique user packages',
    ru: 'Доп. пакеты уников',
  },
  'Message additional sessions package': {
    en: 'Additional package, sessions/month',
    ru: 'Пакет продления, сессии/мес',
  },
  'Message tariffication by uniques': {
    en: 'By unique users',
    ru: 'По уникальным пользователям',
  },
  'Message tariffication by sessions': {
    en: 'By sessions',
    ru: 'По сессиям',
  },
  packages1: {
    en: 'package',
    ru: 'пакет',
  },
  packages2: {
    en: 'packages',
    ru: 'пакета',
  },
  packages5: {
    en: 'packages',
    ru: 'пакетов',
  },
  'Message after activation': {
    en: 'after activation',
    ru: 'после активации',
  },
  'Message after activation time': {
    en: '1 year ',
    ru: '1 год ',
  },
  'Message after activation unlimited': {
    en: 'Indefinitely',
    ru: 'Бессрочно',
  },
  from: {
    en: 'from',
    ru: 'с',
  },
  'Required field': {
    en: 'Fill out the required field',
    ru: 'Заполните обязательное поле',
  },
  'Message is unlimited': {
    en: 'Unlimited',
    ru: 'Не ограничено',
  },
  'Invalid email': {
    en: 'Invalid email address',
    ru: 'Неверный адрес почты',
  },
  'Clients name': {
    en: 'Name',
    ru: 'Название',
  },
  'Clients planning': {
    en: 'Plan limits',
    ru: 'Тарификация',
  },
  'Clients key': {
    en: 'Key',
    ru: 'Ключ',
  },
  'Clients expired at': {
    en: 'Active until',
    ru: 'Активен до',
  },
  'Clients expired at days': {
    en: 'Expires in',
    ru: 'Истекает через',
  },
  'Clients packages': {
    en: 'Connected',
    ru: 'Подключено',
  },
  'Clients last connection': {
    en: 'Last connection',
    ru: 'Последняя связь',
  },
  'Clients SESSIONS': {
    en: 'Sessions',
    ru: 'Сессии',
  },
  'Clients UNIQUE_USERS': {
    en: 'Unique users',
    ru: 'Уникальные пользователи',
  },
  'Clients status ACTIVATION_PENDING': {
    en: 'Activated',
    ru: 'Активирован',
  },
  'Clients status ACTIVATION': {
    en: 'Activated',
    ru: 'Активирован',
  },
  'Clients status ACTIVATED': {
    en: 'Activated',
    ru: 'Активирован',
  },
  'Clients status CREATED': {
    en: 'Not activated',
    ru: 'Не активирован',
  },
  'Clients status REGISTERED': {
    en: 'Not activated',
    ru: 'Не активирован',
  },
  'Clients status REPLACED': {
    en: 'Used',
    ru: 'Использован',
  },
  'Clients status REVOKED': {
    en: 'Blocked',
    ru: 'Заблокирован',
  },
  'Clients package 1': {
    en: 'package',
    ru: 'пакет',
  },
  'Clients package 2': {
    en: 'packages',
    ru: 'пакета',
  },
  'Clients package 5': {
    en: 'packages',
    ru: 'пакетов',
  },
  'Clients day 1': {
    en: 'day',
    ru: 'день',
  },
  'Clients day 2': {
    en: 'days',
    ru: 'дня',
  },
  'Clients day 5': {
    en: 'days',
    ru: 'дней',
  },
  Clients: {
    en: 'Clients',
    ru: 'Клиенты',
  },
  'Clients status not created': {
    en: 'Not created',
    ru: 'Не создан',
    cn: 'No translate!',
  },
  'Additional uniques packages': {
    en: 'Additional unique user packages',
    ru: 'Дополнительные пакеты уников',
  },
  'Add client': {
    en: 'Add client',
    ru: 'Добавить клиента',
  },
  'Clients not found': {
    en: 'Clients not found',
    ru: 'Клиенты не найдены',
  },
  'Client search': {
    en: 'Client search',
    ru: 'Поиск клиента',
  },
  'Key validity period one year': {
    en: 'Validity period: 1 year',
    ru: 'Период действия: 1 год',
  },
  'Back to clients': {
    en: 'Back to the client list',
    ru: 'Вернуться к списку клиентов',
  },
  'Client Key, plan, limit': {
    en: 'Keys',
    ru: 'Ключи',
  },
  'Client activated at': {
    en: 'Enabled',
    ru: 'Подключен',
  },
  'Client key dates': {
    en: 'Valid for',
    ru: 'Действует',
  },
  'Client expired at': {
    en: 'Active until',
    ru: 'Активен до',
  },
  'Client expired at days': {
    en: 'Expires in',
    ru: 'Истекает через',
  },
  'Client key lines': {
    en: 'Number of lines',
    ru: 'Кол-во линий',
  },
  'Client key status': {
    en: 'Status',
    ru: 'Статус',
  },
  'Client key version 1': {
    en: '1.10.7 and lower',
    ru: '1.10.7 и ниже',
  },
  'Client key version 2': {
    en: '1.11.1 - 1.11.7',
    ru: '1.11.1 - 1.11.7',
  },
  'Client key version 3': {
    en: '1.11.12 - 1.11.18',
    ru: '1.11.12 - 1.11.18',
  },
  'Client key version 4': {
    en: '1.12.0 and higher',
    ru: '1.12.0 и выше',
  },
  'Client key version': {
    en: 'Version',
    ru: 'Версия',
  },
  'Client adding': {
    en: 'Add client',
    ru: 'Добавление клиента',
  },
  'Add client button': {
    en: 'Add client',
    ru: 'Добавить клиента',
  },
  'AddClientModal consumer name': {
    en: 'Client name',
    ru: 'Название клиента',
  },
  'AddClientModal phone': {
    en: 'Phone number',
    ru: 'Телефон',
  },
  'AddClientModal report emails title': {
    en: 'List of contact email addresses',
    ru: 'Список контактных email адресов',
  },
  'AddClientModal report emails helper': {
    en: 'To add another email, click Enter',
    ru: 'Чтобы добавить другой email, нажмите Enter',
  },
  'Clients view client': {
    en: 'View client information',
    ru: 'Посмотреть карточку клиента',
  },
  'Clients text message': {
    en: 'Write a message',
    ru: 'Написать сообщение',
  },
  'Clients block client': {
    en: 'Block',
    ru: 'Заблокировать',
  },
  'Clients unlock client': {
    en: 'Unblock',
    ru: 'Разблокировать',
  },
  'BlockClientModal block client': {
    en: 'Block',
    ru: 'Заблокировать',
  },
  'BlockClientModal block client title': {
    en: 'Block client',
    ru: 'Заблокировать клиента',
  },
  'BlockClientModal block client text': {
    en: 'The platform is currently running. If you confirm blocking, chatbots will be stopped.',
    ru: 'В настоящий момент платформа работает. Если вы подтвердите блокирование, будет остановлена работа чат-ботов.',
  },
  'BlockClientModal block client add text': {
    en: 'To reactivate the platform, select the "Unblock client" button',
    ru: 'Восстановить работу платформы можно с помощью кнопки «Разблокировать клиента»',
  },
  'BlockClientModal block client text not active': {
    en:
      'At the moment, the platform is not working but can be activated with a key linked to the client. If you confirm the blocking, activation using linked keys will no longer be possible.',
    ru:
      'В настоящий момент платформа не работает, но может быть активирована привязанным к клиенту ключом. Если вы подтвердите блокирование, активация с помощью привязанных ключей станет невозможной.',
  },
  'BlockClientModal block client add text not active': {
    en: 'To restore the keys, select the "Unblock client" button',
    ru: 'Восстановить ключи можно с помощью кнопки «Разблокировать клиента»',
  },
  'BlockClientModal unlock client': {
    en: 'Unblock',
    ru: 'Разблокировать',
  },
  'BlockClientModal unlock client title': {
    en: 'Unblock client',
    ru: 'Разблокировать клиента',
  },
  'BlockClientModal unlock client text': {
    en:
      'At the moment the platform is blocked and all chatbots are disabled. If you unblock the client, the use of the platform will be restored',
    ru:
      'В данный момент платформа заблокирована и все чат-боты отключены. Если вы разблокируете клиента, то использование платформы будет восстановлено',
  },
  'Client session spending': {
    en: 'Session consumption',
    ru: 'Расход сессий',
  },
  'Client uniques spending': {
    en: 'Consumption of unique users',
    ru: 'Расход уников',
  },
  'Client Create new key': {
    en: 'Create key',
    ru: 'Создать ключ',
  },
  'Client Import key': {
    en: 'Key import',
    ru: 'Импорт ключа',
  },
  'Client block client': {
    en: 'Block client',
    ru: 'Заблокировать клиента',
  },
  'Client unlock client': {
    en: 'Unblock client',
    ru: 'Разблокировать клиента',
  },
  'Client add email': {
    en: 'Add',
    ru: 'Добавить',
  },
  'Client status ACTIVATED': {
    en: 'Activated',
    ru: 'Активирован',
  },
  'Client start date': {
    en: 'Enabled',
    ru: 'Подключен',
  },
  'Client valid to': {
    en: 'Valid until',
    ru: 'Действителен до',
  },
  'Client unique users title': {
    en: 'Unique users',
    ru: 'Уникальные пользователи',
  },
  'Client sessions title': {
    en: 'Sessions',
    ru: 'Сессии',
  },
  'Client unique users count': {
    en: 'unique users',
    ru: 'уникальных пользователей',
  },
  'Client sessions count': {
    en: 'sessions',
    ru: 'сессий',
  },
  'Client packages title': {
    en: 'Packages added',
    ru: 'Подключенные пакеты',
  },
  'Client contacts title': {
    en: 'Contacts',
    ru: 'Контакты',
  },
  'Client 365 days from activation': {
    en: '365 days from activation',
    ru: '365 дней с момента активации',
  },
  'Client Online': {
    en: 'Online',
    ru: 'Онлайн',
  },
  'Client Offline': {
    en: 'Offline',
    ru: 'Оффлайн',
  },
  'Client no data': {
    en: 'No data',
    ru: 'Нет данных',
  },
  'Client modal contacts title': {
    en: 'Client contacts',
    ru: 'Контакты клиента',
  },
  'Client save emails button': {
    en: 'Save',
    ru: 'Сохранить',
  },
  'Client sessions spending': {
    en: 'Session consumption',
    ru: 'Расход сессий',
  },
  'Client Blocked': {
    en: 'Blocked',
    ru: 'Заблокирован',
  },
  'consumer.name.already.exists': {
    en: 'Client name must be unique',
    ru: 'Название клиента должно быть уникальным',
  },
  'Clients Add vendor': {
    en: 'Add vendor',
    ru: 'Добавить вендора',
  },
  'vendor.name.already.exists': {
    en: 'Vendor name must be unique',
    ru: 'Имя вендора должно быть уникальным',
  },
  'Client tooltip delete': {
    en: 'Delete',
    ru: 'Удалить',
  },
  'Client tooltip unavailable delete': {
    en: 'To delete a vendor, clear the client list',
    ru: 'Чтобы удалить вендора, очистите список клиентов',
  },
  'Sign in': {
    en: 'Sign in to your account',
    ru: 'Войдите в аккаунт',
  },
  'Log in': {
    en: 'Sign in',
    ru: 'Войти',
  },
  Password: {
    en: 'Password',
    ru: 'Пароль',
  },
  Email: {
    en: 'Email',
    ru: 'Email',
  },
  'key.not.found': {
    en: 'Key not found',
    ru: 'Ключ не найден',
  },
  'import.key.exist': {
    en: 'Key already exists',
    ru: 'Ключ уже существует',
  },
  'Import key btn': {
    en: 'Key import',
    ru: 'Импорт ключа',
  },
  'Wrong login or password': {
    en: 'Incorrect login or password',
    ru: 'Неверный логин или пароль',
    cn: '登录名或密码不对',
  },
  'Incorrect token': {
    en: 'Incorrect token',
    ru: 'Неверный токен',
    cn: '令牌不正确',
  },
  'Reset password': {
    en: 'Reset',
    ru: 'Сбросить пароль',
    cn: '重置密码',
  },
  'invalid.signature': {
    en:
      'The downloaded file does not contain a license key for the JAICP platform. Contact the key provider to obtain the correct file.',
    ru:
      'Загруженный файл не содержит лицензионный ключ для платформы JAICP. Обратитесь к поставщику ключа для получения правильного файла.',
  },
  'Clients statistics': {
    en: 'Statistics',
    ru: 'Статистика',
  },
  'Clients export statistics': {
    en: 'Export',
    ru: 'Экспорт',
  },
  'Clients import statistics': {
    en: 'Import',
    ru: 'Импорт',
  },
  'ExportModal export title': {
    en: 'Export usage statistics',
    ru: 'Экспорт статистики использования',
  },
  'ExportModal export': {
    en: 'Export',
    ru: 'Экспортировать',
  },
  'ExportModal export period': {
    en: 'Specify the period for which the upload is required',
    ru: 'Укажите период, за который требуется выгрузка',
  },
  'ExportModal export file': {
    en: 'We will generate a file for download with the usage statistics limits for the specified period',
    ru: 'Мы сформируем файл для скачивания со статистикой использования лимитов за указанный период',
  },
  'ExportModal month': {
    en: 'Month',
    ru: 'Месяц',
  },
  'ExportModal year': {
    en: 'Year',
    ru: 'Год',
  },
  'ExportModal month 1': {
    en: 'January',
    ru: 'Январь',
  },
  'ExportModal month 2': {
    en: 'February',
    ru: 'Февраль',
  },
  'Clients imported key for $[1]': {
    en: 'License key added to client "$[1]"',
    ru: 'Лицензионный ключ добавлен клиенту "$[1]"',
  },
  'Key details': {
    en: 'Key details',
    ru: 'Детали ключа',
  },
  Close: {
    en: 'Close',
    ru: 'Закрыть',
  },
  'Client license type': {
    en: 'Type',
    ru: 'Тип лицензии',
  },
  'Client used limits': {
    en: 'Consumption: remaining/total',
    ru: 'Расход: осталось/всего',
  },
  'AddKeyModal copy key': {
    en: 'Copy key',
    ru: 'Скопировать ключ',
  },
  'Client emails title': {
    en: 'Email:',
    ru: 'Email:',
  },
  'Client phone title': {
    en: 'Phone Number:',
    ru: 'Телефон:',
  },
  'Client uuid title': {
    en: 'UUID:',
    ru: 'UUID:',
  },
  'Client comment title': {
    en: 'Comments',
    ru: 'Комментарии',
  },
  'AddClientModal wrong phone format': {
    en: 'Invalid format',
    ru: 'Неверный формат',
  },
  'ExportModal month 3': {
    en: 'March',
    ru: 'Март',
  },
  'ExportModal month 4': {
    en: 'April',
    ru: 'Апрель',
  },
  'ExportModal month 5': {
    en: 'May',
    ru: 'Май',
  },
  'ExportModal month 6': {
    en: 'June',
    ru: 'Июнь',
  },
  'ExportModal month 7': {
    en: 'July',
    ru: 'Июль',
  },
  'ExportModal month 8': {
    en: 'August',
    ru: 'Август',
  },
  'ExportModal month 9': {
    en: 'September',
    ru: 'Сентябрь',
  },
  'ExportModal month 10': {
    en: 'October',
    ru: 'Октябрь',
  },
  'ExportModal month 11': {
    en: 'November',
    ru: 'Ноябрь',
  },
  'ExportModal month 12': {
    en: 'December',
    ru: 'Декабрь',
  },
  'ExportModal period not ended': {
    en:
      'The selected period is not completed. To get the final data for the specified period, export the statistics again in the following month',
    ru:
      'Выбранный период не завершен. Чтобы получить окончательные данные за указанный период, повторите экспорт статистики в следующем месяце',
  },
  'ImportModal import title': {
    en: 'Statistics import',
    ru: 'Импорт статистики',
  },
  'ImportModal import': {
    en: 'Confirm import',
    ru: 'Подтвердить импорт',
  },
  'ImportModal import text': {
    en: 'Client statistics will be imported:',
    ru: 'Будет импортирована статистика клиентов:',
  },
  'ImportModal ready to import': {
    en: 'Ready for import',
    ru: 'Готов к импорту',
  },
  'ImportModal is importing': {
    en: 'Importing...',
    ru: 'Импортируется...',
  },
  'ImportModal is imported close': {
    en: 'Close',
    ru: 'Закрыть',
  },
  'ImportModal is imported title': {
    en: 'Import report',
    ru: 'Отчет импорта',
  },
  'ImportModal is imported text': {
    en: 'Statistics import results:',
    ru: 'Результаты импортирования статистики:',
  },
  'ImportModal is importing text': {
    en: 'Wait until the client statistics import is completed:',
    ru: 'Дождитесь окончания импортирования статистики клиентов:',
  },
  'ImportModal status IN_PROGRESS': {
    en: 'In progress...',
    ru: 'В процессе...',
  },
  'ImportModal status BAD_CHECKSUM': {
    en: 'Statistics checksum error',
    ru: 'Ошибка контрольной суммы статистики',
  },
  'ImportModal status NO_PREVIOUS_IMPORT': {
    en: 'There are no statistics for the previous period',
    ru: 'Нет статистики за предыдущий период',
  },
  'ImportModal status OVERDRAFT': {
    en: 'Exceeding limits',
    ru: 'Превышение лимитов',
  },
  'ImportModal status SUCCESS': {
    en: 'OK',
    ru: 'Ok',
  },
  'ImportModal status READY': {
    en: 'In queue',
    ru: 'В очереди',
  },
  'Clients no keys for export': {
    en: 'There are no keys with active statistics in the license server',
    ru: 'В лицензионном сервере отсутствуют ключи с активной статистикой',
  },
  'import.stats.format.invalid': {
    en: 'Incorrect archive format uploaded',
    ru: 'Загружен архив неверного формата',
  },
  'ImportModal no import stats': {
    en: 'The uploaded statistics file does not contain data on the imported keys',
    ru: 'В загруженном файле статистики отсутствуют данные об импортированных ключах',
  },
  'hour 1': {
    en: 'hour',
    ru: 'час',
  },
  'hour 2': {
    en: 'hours',
    ru: 'часа',
  },
  'hour 5': {
    en: 'hours',
    ru: 'часов',
  },
  'minute 1': {
    en: 'minute',
    ru: 'минута',
  },
  'minute 2': {
    en: 'minutes',
    ru: 'минуты',
  },
  'minute 5': {
    en: 'minutes',
    ru: 'минут',
  },
  'max.upload.size.exceeded': {
    en: 'Maximum upload size exceeded',
    ru: 'Превышен максимальный размер файла',
  },
  'EmailNotificationModal check email': {
    en: 'Check your email',
    ru: 'Проверьте почту',
  },
  'EmailNotificationModal text': {
    en:
      'A verification email was sent to $[1]. Make sure you can receive messages from the license server.<br/>If there is no email, add another address or check your email settings. Otherwise, the license server will not be able to send a warning about the platform shutdown or expiration of the keys.',
    ru:
      'На $[1] выслано проверочное письмо. Проверьте, что вы можете получать сообщения от лицензионного сервера.<br/>Если письма нет, добавьте другой email или проверьте настройки почты. Иначе лицензионный сервер не сможет прислать предупреждение об отключении платформы и истечении действия ключей.',
  },
  'AddKeyModal: key period 1': {
    en: '1 month',
    ru: '1 месяц',
  },
  'AddKeyModal: key period 3': {
    en: '3 months',
    ru: '3 месяца',
  },
  'AddKeyModal: key period 6': {
    en: '6 months',
    ru: '6 месяцев',
  },
  'AddKeyModal: key period 12': {
    en: '12 months',
    ru: '12 месяцев',
  },
  'AddKeyModal: key period other': {
    en: 'Custom period',
    ru: 'Произвольный период',
  },
  'AddKeyModal: key period label': {
    en: 'Validity period',
    ru: 'Период действия',
  },
  'AddKeyModal: start date': {
    en: 'Start date',
    ru: 'Дата начала',
  },
  'AddKeyModal: end date': {
    en: 'End date',
    ru: 'Дата окончания',
  },
  'AddKeyModal: blockExistingKeys checkbox': {
    en: 'When importing a key, forcibly block the valid key and the keys in the queue',
    ru: 'При импорте ключа принудительно заблокировать действующий ключ и ключи в очереди',
  },
  'AddKeyModal: basic period type entire period': {
    en: 'Entire period',
    ru: 'Весь период',
  },
  'AddKeyModal: basic period type entire period message': {
    en: 'for entire period',
    ru: 'на весь период',
  },
  to: {
    en: 'for',
    ru: 'по',
  },
  'import.key.period.exceeded': {
    en: 'The key has expired. Please contact the manager',
    ru: 'Срок действия ключа истек. Обратитесь к менеджеру',
  },
  'import.key.period.intersection': {
    en:
      'The key cannot be imported because there are date overlaps with the following keys: $[1]. Please contact the manager.',
    ru:
      'Данный ключ не может быть импортирован, так как имеются пересечения по дате со следующими ключами: $[1]. Обратитесь к менеджеру.',
  },
  'key.import.unsupported.version': {
    en: 'Key import is not allowed. Your key version: $[1]. Supported key version: $[2]. Contact your key provider.',
    ru:
      'Импорт загружаемого ключа не поддерживается. Версия загружаемого ключа: $[1]. Поддерживаемая версия: $[2]. Обратитесь к поставщику ключа.',
  },
  'AddKeyModal: key type 1': {
    en: '1.10.7 and lower',
    ru: '1.10.7 и ниже',
  },
  'AddKeyModal: key type 2': {
    en: '1.11.1 - 1.11.7',
    ru: '1.11.1 - 1.11.7',
  },
  'AddKeyModal: key type 3': {
    en: '1.11.12 - 1.11.18',
    ru: '1.11.12 - 1.11.18',
  },
  'AddKeyModal: key type 4': {
    en: '1.12.0 and higher',
    ru: '1.12.0 и выше',
  },
  'AddKeyModal: feature jgraph_licensed': {
    en: 'J-Graph',
    ru: 'J-Graph',
  },
  'AddKeyModal: feature outbound_messages_licensed': {
    en: 'Text campaigns',
    ru: 'Рассылки',
  },
  'AddKeyModal: feature botadmin_log_labeling_feature': {
    en: 'Log labeling',
    ru: 'Log labeling',
  },
  'AddKeyModal: feature imputer': {
    en: 'Imputer',
    ru: 'Imputer',
  },
  'AddKeyModal: feature knowledge_base': {
    en: 'Knowledge base',
    ru: 'База знаний',
  },
  'AddKeyModal: feature livechat_licensed': {
    en: 'Livechat',
    ru: 'Livechat',
  },
  'AddKeyModal: feature prompter_licensed': {
    en: 'Prompter',
    ru: 'Суфлер',
  },
  'AddKeyModal:KeyVersion:text': {
    en: 'Starting from version 1.12.0, additional packages do not expire after key activation',
    ru: 'Начиная с версии 1.12.0, дополнительные пакеты не сгорают после активации',
  },
  'AddKeyModal:LicenseType': {
    en: 'License type',
    ru: 'Тип лицензии',
  },
  'AddKeyModal:LicenseType:FULL': {
    en: 'Full license',
    ru: 'Полная лицензия',
  },
  'AddKeyModal:LicenseType:TEXT_CHANNELS': {
    en: 'Inbound channels',
    ru: 'Текстовые каналы',
  },
  'AddKeyModal:LicenseType:TELEPHONY_CHANNELS': {
    en: 'Phone channels',
    ru: 'Телефонные каналы',
  },
  'AddKeyModal:KeyPeriod:Text': {
    en:
      'The period is the same for inbound and phone channels. For Billing by unique users, only the Entire period option is available. ',
    ru:
      'Период общий для текстовых и телефонных каналов. Для уникальных пользователей доступна только тарификация на весь период. ',
  },
  'AddKeyModal:TelephonyChannels': {
    en: 'Phone lines',
    ru: 'Телефонные линии',
  },
  'AddKeyModal:TelephonyChannels:LinesCount': {
    en: 'Number of phone lines',
    ru: 'Кол-во телефонных линий',
  },
  'AddKeyModal:TelephonyChannels:NluMinutes:Packages': {
    en: 'Additional minute packages',
    ru: 'Дополнительные пакеты минут',
  },
  'AddKeyModal:TelephonyChannels:RoundInterval': {
    en: 'Rounding interval',
    ru: 'Интервал округления',
  },
  'AddKeyModal:TelephonyChannels:RoundInterval:1': {
    en: '1 sec',
    ru: '1 сек',
  },
  'AddKeyModal:TelephonyChannels:RoundInterval:15': {
    en: '15 sec',
    ru: '15 сек',
  },
  'AddKeyModal:TelephonyChannels:RoundInterval:60': {
    en: '1 min',
    ru: '1 мин',
  },
  'AddKeyModal:DisabledFieldText': {
    en: 'no',
    ru: 'нет',
  },
  'AddKeyModal:DefaultKeyName': {
    en: 'Key for client $[1]',
    ru: 'Ключ для клиента $[1]',
  },
  'KeyItem:LicenseType:text': {
    en: 'Inbound channels',
    ru: 'Текстовые каналы',
  },
  'KeyItem:LicenseType:telephony': {
    en: 'Phone channels',
    ru: 'Телефонные каналы',
  },
  'KeyItem:LicenseType:full': {
    en: 'Full',
    ru: 'Полная',
  },
  'KeyItem:BasicPackage': {
    en: 'Main package $[1]',
    ru: 'Основной пакет $[1]',
  },
  'KeyItem:AdditionPackage': {
    en: 'Additional package $[1]',
    ru: 'Дополнительный пакет $[1]',
  },
  'KeyItem:BasicPackage:UNIQUE_USERS': {
    en: 'unique users',
    ru: 'уников',
  },
  'KeyItem:BasicPackage:SESSIONS': {
    en: 'sessions',
    ru: 'сессий',
  },
  'KeyItem:FullPackages:one': {
    en: 'full package',
    ru: 'полный',
  },
  'KeyItem:FullPackages:few': {
    en: 'full packages',
    ru: 'полных',
  },
  'KeyItem:FullPackages:many': {
    en: 'full packages',
    ru: 'полных',
  },
  'KeyItem:FullPackages:other': {
    en: 'full packages',
    ru: 'полных',
  },
  'KeyItem:NotFullPackages': {
    en: 'and 1 for ',
    ru: 'и 1 на ',
  },
  'KeyItem:By': {
    en: 'of',
    ru: 'по',
  },
  'KeyItem:NoPackages': {
    en: 'not added',
    ru: 'не добавлено',
  },
  'KeyItem:DaysLeft:one': {
    en: '$[1] day left',
    ru: 'еще $[1] день',
  },
  'KeyItem:DaysLeft:few': {
    en: '$[1] days left',
    ru: 'еще $[1] дня',
  },
  'KeyItem:DaysLeft:many': {
    en: '$[1] days left',
    ru: 'еще $[1] дней',
  },
  'KeyItem:DaysLeft:other': {
    en: '$[1] days left',
    ru: 'еще $[1] дня',
  },
  'ClientTariffInfo:BillingPeriod': {
    en: 'billing period',
    ru: 'период тарификации',
  },
  'ClientTariffInfo:BillingPeriod:MONTHLY': {
    en: '1 month',
    ru: '1 месяц',
  },
  'ClientTariffInfo:BillingPeriod:ENTIRE_PERIOD': {
    en: 'entire period',
    ru: 'весь период',
  },
  'KeyChart:TotalConsumption': {
    en: 'Total consumption',
    ru: 'Общий расход',
  },
  'KeyChart:KeyLimit': {
    en: 'Key limit',
    ru: 'Лимит ключа',
  },
  'KeyChart:TariffConsumption': {
    en: 'Limit consumption',
    ru: 'Расход по лимиту',
  },
  'KeyChart:PackagesConsumption': {
    en: 'Package consumption',
    ru: 'Расход по пакетам',
  },
};
