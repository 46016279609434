import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import { withStyles } from 'altreidsds';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import SnackbarContainer from '../../components/SnackbarContainer';

import localize from '../../localization';
import * as loginActions from '../../actions/currentUser.actions';
import { getInstanceLanguage } from '../../actions/currentUser.actions';
const drawerWidth = 260;
const drawerPaperCloseWidth = 80;

const styles = theme => ({
  bannerCustomClass: {
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '1200',
  },
  noMargin: {
    '&.p': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  content: {
    position: 'relative',
    width: `calc(100% - ${drawerPaperCloseWidth}px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: '24px 24px 0',
    height: 'calc(100vh - 56px)',
    marginTop: 52,
    marginLeft: `${drawerPaperCloseWidth}px`,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
      height: '100%',
      marginTop: 52,
    },
  },
  contentShift: {
    marginLeft: `${drawerWidth}px`,
  },
  snackbar: {
    position: 'fixed',
    left: `${drawerPaperCloseWidth}px`,
    bottom: '50px',
  },
  snackbarShift: {
    left: `${drawerWidth}px`,
  },
});

class Full extends Component {
  state = {
    load: false,
    open: false,
    openFeedbackModal: false,
    createFromTemplateModal: false,
  };

  async componentWillMount() {
    const {
      value: { data },
    } = await this.props.getInstanceLanguage();
    localize.setLocale(data.language.toLowerCase());

    this.setState({
      load: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    const currentLang = this.props.language;
    const nextLang = nextProps.language;

    if (currentLang !== nextLang) {
      localize.setLocale(nextLang);
    }
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open });
    document.body.classList.toggle('sidebar-open');
  };

  logoutAction = () => {
    this.props.logout();
  };

  render() {
    const { classes, currentUser } = this.props;
    return this.state.load ? (
      <div className={classes.appFrame}>
        <Header
          open={this.state.open}
          location={this.props.location}
          setUserLang={this.props.setUserLang}
          language={this.props.language}
        />

        <Sidebar
          open={this.state.open}
          toggleDrawer={this.toggleDrawer}
          logout={this.logoutAction}
          currentUser={currentUser}
        />
        <main id='main_content' className={classNames(classes.content, this.state.open && classes.contentShift)}>
          {this.props.children}
        </main>
        <SnackbarContainer className={classNames(classes.snackbar, this.state.open && classes.snackbarShift)} />
      </div>
    ) : (
      <Spinner />
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
    language: state.CurrentUserReducer.language,
  };
}

const mapDispatchToProps = dispatch => ({
  loginActions: bindActionCreators(
    {
      ...loginActions,
    },
    dispatch
  ),
  getInstanceLanguage: bindActionCreators(getInstanceLanguage, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Full)));
