import React, { Component } from 'react';
import localize from '../../localization';
import { Grid, Spinner, Typography, withStyles } from 'altreidsds';
import AppIcon from '../../components/AppIcon';
import { getInstanceLanguage } from '../../actions/currentUser.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    minHeight: '100vh',
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.default,
  },
  page: {
    minHeight: `84vh`,
  },
  brandName: {
    fontSize: '0.75rem',
    color: 'rgba(42, 39, 34, 0.3)',
    lineHeight: '1rem',
    fontWeight: 600,
    marginRight: 4,
  },
});

class Simple extends Component {
  state = {
    loaded: false,
  };
  async componentWillMount() {
    const {
      value: { data },
    } = await this.props.getInstanceLanguage();
    localize.setLocale(data.language.toLowerCase());
    this.setState({
      loaded: true,
    });
  }

  render() {
    const { classes } = this.props;
    const { loaded } = this.state;

    if (!loaded) return <Spinner />;

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <main id='main_content' className={classes.content}>
            <Grid
              direction='column'
              className={classes.page}
              container
              spacing={8}
              justify='center'
              alignItems='center'
            >
              <Grid item>
                <AppIcon size='large' />
              </Grid>
              <Grid item>{this.props.children}</Grid>
              <Grid item>
                <Grid container spacing={0} justify='center'>
                  <Grid item>
                    <Typography className={classes.brandName}>License Server</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { getInstanceLanguage: bindActionCreators(getInstanceLanguage, dispatch) };
};

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Simple));
